import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";

import * as styles from "./hero.module.css";

const hero = ({ data }) => (
  <div className={styles.hero}>
    <Img
      className={styles.heroImage}
      alt={data.title}
      fluid={data.heroImage.fluid}
    />
    <div className={styles.heroDetails}>
      <h1 className={styles.heroHeadline}>{data.title}</h1>
      {data.buttonText && (
        <Link className="btn" to={data.buttonLink}>
          {data.buttonText}
        </Link>
      )}
    </div>
  </div>
);

export default hero;
